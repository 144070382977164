import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import LockIcon from '@material-ui/icons/Lock';
import Grid from '@material-ui/core/Grid';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import GrainIcon from '@material-ui/icons/Grain';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

import ReCaptchaV2 from 'react-google-recaptcha';
const openpgp = require('openpgp');

const useRowStyles = makeStyles({
  tableHead: {
    fontWeight: 'bold',
  },
  textHead: {
    fontWeight: 'bold',
    color:'#111827'
  }
});

function BodyComposition(props) {
  const [open, setOpen] = React.useState(true);
  const classes = useRowStyles();
  //console.log('bdc', props)
  const bdc = [
    { name: 'Massa Magra',
      peso: props.mm ? props.mm.kg : 0,
      porcento: props.mm ? props.mm.por : 0,
      color: '#FEF2F2',
      icon: <GraphicEqIcon style={{fill:'#7F1D1D'}} />
    },
    { name: 'Gordura',
      peso: props.mg ? props.mg.kg : 0,
      porcento: props.mg ? props.mg.por : 0,
      color: '#FFFBEB',
      icon: <GrainIcon style={{fill:'#FBBF24'}} />
    },
  ]
  const h2o = [
    { name: 'Água',
      peso: props.ag ? props.ag.kg : 0,
      porcento: props.ag ? props.ag.por : 0,
      color: '#EFF6FF',
      icon: <BubbleChartIcon style={{fill:'#1E3A8A'}} />
    }
  ]

  return (
    <React.Fragment>
      <TableContainer id="body-composition-table" component={Paper} style={{textAlign:'center', minWidth:'360px', marginBottom:'24px'}}>
        <Table aria-label="collapsible table">

          <TableHead 
            style={{ backgroundColor:'#6EE7B7'}}
          >
                       
            <TableRow>
              <TableCell colSpan={1} >
                <IconButton aria-label="expand row" size="small" style={{color:'black'}} onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>

              <TableCell align="center" colSpan={2}>
                <Typography variant="h6" component="h1" style={{fontWeight: 'bold', color:'#111827', textTransform:'uppercase'}}>
                  Composição Corporal
                </Typography>
              </TableCell>

            </TableRow>
          </TableHead>


          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={3}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table size="small" aria-label="purchases">
                      
                      <TableHead>
                        <TableCell colSpan={3}>
                          <span style={{marginLeft:'8px'}}>
                            a sua composição corporal atual é:
                          </span>
                        </TableCell>
                      </TableHead>

                      <TableBody>
                        <TableRow style={{backgroundColor:'#ECFDF5'}}>
                          <TableCell align="center" className={classes.tableHead}>Tecido</TableCell>
                          <TableCell align="center" className={classes.tableHead}>Peso (kg)</TableCell>
                          <TableCell align="center" className={classes.tableHead}>Porcentagem (%)</TableCell>
                        </TableRow>

                        {
                          bdc.map(
                            (val) => (
                              <TableRow style={{backgroundColor: val.color}}>
                                
                                <TableCell align="center"
                                  style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}
                                >
                                    {val.icon}
                                  <Typography variant="body1" component="p" style={{fontWeight:'bold', lineHeight:'1rem'}}>
                                    {val.name}
                                  </Typography>
                                </TableCell>

                                <TableCell align="center">
                                  {val.peso}
                                </TableCell>

                                <TableCell align="center">
                                  {val.porcento}
                                </TableCell>
                                
                              </TableRow>
                            )
                          )
                        }

                        <TableRow>
                          <TableCell colSpan={3}>
                            <span style={{marginLeft:'8px'}}>
                              o seu volume de água corporal atual é:
                            </span>
                          </TableCell>
                        </TableRow>

                        {
                            h2o.map(
                              (val) => (
                                <TableRow style={{backgroundColor: val.color}}>
                                  
                                  <TableCell align="center"
                                    style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}
                                  >
                                      {val.icon}
                                    <Typography variant="body1" component="p" style={{fontWeight:'bold', lineHeight:'1rem'}}>
                                      {val.name}
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="center">
                                    {val.peso} L
                                  </TableCell>

                                  <TableCell align="center">
                                    {val.porcento}
                                  </TableCell>
                                  
                                </TableRow>
                              )
                            )
                          }

                      </TableBody>

                    </Table>  
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function TM(props) {
  
  return (
    <React.Fragment>
      <TableContainer id="taxa-metabolica-table" component={Paper} style={{textAlign:'center', minWidth:'360px', marginBottom:'24px'}}>
        <Table aria-label="collapsible table">

          <TableHead style={{backgroundColor:'#047857'}}>
            <TableRow>
              
              <TableCell align="center" colSpan={2}
                style={{fontWeight: 'bold', color:'#111827', textTransform:'uppercase', position:'relative', backgroundColor:'#C4B5FD'}}
              >
                <FlashOnIcon style={{position:'absolute', fill:'#F5F3FF', fillOpacity:0.5, left:0, top:0, zIndex:1, width:'100%', height:'100%'}} />
                <Typography variant="h6" component="h1" style={{position:'relative', zIndex:2, fontWeight: 'bold', color:'#111827', textTransform:'uppercase'}}>
                  Taxa Metabólica (TM)
                </Typography>
              
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                <span style={{marginLeft:'8px'}}>
                  sua necessidade energética é:
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" style={{fontWeight: 'bold', color:'#111827'}}>
                <Typography id="tbmatual" variant="h5" component="p">
                  {props.tm} <small>kcal</small>
                </Typography>
              </TableCell>
            </TableRow>

          </TableBody>

        </Table>
      </TableContainer>
    </React.Fragment>

  )
}


export default function CollapsibleTable(props) {
  // macros
  const [CarboVal, setCarboVal] = React.useState(50);
  const handleChangeCarboVal = (event) => {
    setCarboVal(event.target.value);
  };

  const [ProtVal, setProtVal] = React.useState(40);
  const handleChangeProtVal = (event) => {
    setProtVal(event.target.value);
  };
  
  const [FatVal, setFatVal] = React.useState(10);
  const handleChangeFatVal = (event) => {
    setFatVal(event.target.value);
  };

  const [RdTMVal, setRdTMVal] = React.useState(0);
  const handleChangeRdTMVal = (event) => {
    setRdTMVal(event.target.value);
  };

  const [NrefVal, setNrefVal] = React.useState(3);
  const handleChangeNrefVal = (event) => {
    setNrefVal(event.target.value);
  };
  // macros

  return (
    <>
      <BodyComposition
        mm={props.mm}
        mg={props.mg}
        ag={props.ag}
      />

      <TM tm={props.tm} />

      <MacrosTable
        tm={props.tm}
        CarboVal={CarboVal} handleChangeCarboVal={handleChangeCarboVal}
        ProtVal={ProtVal} handleChangeProtVal={handleChangeProtVal}
        FatVal={FatVal} handleChangeFatVal={handleChangeFatVal}
        RdTMVal={RdTMVal} handleChangeRdTMVal={handleChangeRdTMVal}
        NrefVal={NrefVal} handleChangeNrefVal={handleChangeNrefVal}
      />

      <SendResultForm
        mm={props.mm}
        mg={props.mg}
        ag={props.ag}
        tm={props.tm}
        CarboVal={CarboVal}
        ProtVal={ProtVal}
        FatVal={FatVal}
        RdTMVal={RdTMVal}
        NrefVal={NrefVal}
      />

    </>
  );
}


function MacrosTable(props) {
  const classes = useRowStyles();
  const { tm, CarboVal, ProtVal, FatVal, RdTMVal, NrefVal, handleChangeCarboVal, handleChangeProtVal, handleChangeFatVal, handleChangeRdTMVal, handleChangeNrefVal} = props;
  //refRdTMVal, refCarboVal, refProtVal, refFatVal, refNrefVal
   
  React.useEffect(() => {
    var NewTM = tm - RdTMVal
    
    var TotalNode = document.querySelector('#total-comp-alm');
    TotalNode.innerHTML = CarboVal + ProtVal + FatVal;
    
    var TMrdNode =  document.querySelector('#tm-red-val');
    var TMrdrefNode =  document.querySelector('#tm-redref-val');
    var CarbKcalNode = document.querySelector('#carbo-kcal-val');
    var ProtKcalNode = document.querySelector('#prot-kcal-val');
    var FatKcalNode = document.querySelector('#fat-kcal-val');
    var CarbgNode = document.querySelector('#carbo-g-val');
    var ProtgNode = document.querySelector('#prot-g-val');
    var FatgNode = document.querySelector('#fat-g-val');
    var CarbKcalrefNode = document.querySelector('#carbo-kcal-val-ref');
    var ProtKcalrefNode = document.querySelector('#prot-kcal-val-ref');
    var FatKcalrefNode = document.querySelector('#fat-kcal-val-ref');
    var CarbgrefNode = document.querySelector('#carbo-g-val-ref');
    var ProtgrefNode = document.querySelector('#prot-g-val-ref');
    var FatgrefNode = document.querySelector('#fat-g-val-ref');

    TMrdNode.innerHTML = NewTM + 'kcal'
    TMrdrefNode.innerHTML = NewTM + 'kcal'
    
    CarbKcalNode.innerHTML = Math.round((CarboVal/100) * NewTM);
    CarbgNode.innerHTML =  Math.round(((CarboVal/100) * NewTM) / 4);

    ProtKcalNode.innerHTML = Math.round((ProtVal/100) * NewTM);
    ProtgNode.innerHTML =  Math.round(((ProtVal/100) * NewTM) / 4);

    FatKcalNode.innerHTML = Math.round((FatVal/100) * NewTM);
    FatgNode.innerHTML =  Math.round(((FatVal/100) * NewTM) / 9);

    // por ref

    CarbKcalrefNode.innerHTML = Math.round(((CarboVal/100) * NewTM) / NrefVal);
    CarbgrefNode.innerHTML =  Math.round((((CarboVal/100) * NewTM) / 4) / NrefVal);

    ProtKcalrefNode.innerHTML = Math.round(((ProtVal/100) * NewTM) / NrefVal);
    ProtgrefNode.innerHTML =  Math.round((((ProtVal/100) * NewTM) / 4) / NrefVal);

    FatKcalrefNode.innerHTML = Math.round(((FatVal/100) * NewTM) / NrefVal);
    FatgrefNode.innerHTML =  Math.round((((FatVal/100) * NewTM) / 9) / NrefVal);

    //console.log( CarboVal+ ProtVal+ FatVal)
  }, [CarboVal, ProtVal, FatVal, RdTMVal, NrefVal, tm]);

  return (
    <React.Fragment>

      {/* set reducao energetica */}
      <TableContainer id="reducao-energetica-table" component={Paper} style={{textAlign:'center', minWidth:'360px', marginBottom:'24px'}}>
        <Table aria-label="collapsible table">

          <TableHead style={{backgroundColor:'#047857'}}>
            <TableRow>
              
              <TableCell align="center" colSpan={2}
                style={{fontWeight: 'bold', color:'#111827', textTransform:'uppercase', position:'relative', backgroundColor:'#93C5FD'}}
              >
                <TrendingDownIcon style={{position:'absolute', fill:'#EFF6FF', fillOpacity:0.5, left:0, top:0, zIndex:1, width:'100%', height:'100%'}} />
                <Typography variant="h6" component="h1" style={{position:'relative', zIndex:2, fontWeight: 'bold', color:'#111827', textTransform:'uppercase'}}>
                  Redução Energética
                </Typography>
              
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <span style={{marginLeft:'8px'}}>
                  qual a redução você quer?
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.tableHead}>Energia</TableCell>
              <TableCell align="center" className={classes.tableHead}>kcal</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.tableHead}>Redução da TM</TableCell>
              <TableCell align="center">
                <FormControl variant="outlined">
                  <InputLabel id="rdtm-val-label">Valor</InputLabel>
                  <Select
                      labelId="rdtm-val-label"
                      id="rdtm-val"
                      value={RdTMVal}
                      onChange={handleChangeRdTMVal}
                      label="Valor"
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={300}>300</MenuItem>
                      <MenuItem value={400}>400</MenuItem>
                      <MenuItem value={500}>500</MenuItem>
                      <MenuItem value={600}>600</MenuItem>
                      <MenuItem value={700}>700</MenuItem>
                      <MenuItem value={800}>800</MenuItem>
                      <MenuItem value={900}>900</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

          </TableBody>

        </Table>
      </TableContainer>

      {/* Macros set value */}
      <TableContainer id="macros-setvalues-table" component={Paper} style={{textAlign:'center', minWidth:'360px', marginBottom:'24px'}}>
        <Table aria-label="collapsible table">

          <TableHead style={{backgroundColor:'#047857'}}>
            <TableRow>
              
              <TableCell align="center" colSpan={2}
                style={{fontWeight: 'bold', color:'#111827', textTransform:'uppercase', position:'relative', backgroundColor:'#F9A8D4'}}
              >
                <ControlCameraIcon style={{position:'absolute', fill:'#FDF2F8', fillOpacity:0.5, left:0, top:0, zIndex:1, width:'100%', height:'100%'}} />
                <Typography variant="h6" component="h1" style={{position:'relative', zIndex:2, fontWeight: 'bold', color:'#111827', textTransform:'uppercase'}}>
                  Composição Alimentar
                </Typography>
              
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <span style={{marginLeft:'8px'}}>
                  qual a proporção dos macronutrienes você quer? total tem que dar 100%.
                </span>

              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.tableHead}>Macronutrientes</TableCell>
              <TableCell align="center" className={classes.tableHead}>porcentagem da (%) TM</TableCell>
            </TableRow>


            <TableRow>
              <TableCell align="center" className={classes.tableHead}>Carboidrato</TableCell>
              <TableCell align="center">
                <FormControl variant="outlined">
                  <InputLabel id="carbo-val-label">Valor</InputLabel>
                  <Select
                      labelId="carbo-val-label"
                      id="carbo-val"
                      value={CarboVal}
                      onChange={handleChangeCarboVal}
                      label="Valor"
                    >
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={55}>55</MenuItem>
                      <MenuItem value={60}>60</MenuItem>
                      <MenuItem value={65}>65</MenuItem>
                      <MenuItem value={70}>70</MenuItem>
                      <MenuItem value={75}>75</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.tableHead}>Proteína</TableCell>
              <TableCell align="center">
                <FormControl variant="outlined">
                  <InputLabel id="prot-val-label">Valor</InputLabel>
                  <Select
                      labelId="prot-val-label"
                      id="prot-val"
                      value={ProtVal}
                      onChange={handleChangeProtVal}
                      label="Valor"
                    >
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={35}>35</MenuItem>
                      <MenuItem value={40}>40</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            
            </TableRow>
            
            <TableRow>
              <TableCell align="center" className={classes.tableHead}>Gordura</TableCell>
              <TableCell align="center">
                <FormControl variant="outlined">
                  <InputLabel id="fat-val-label">Valor</InputLabel>
                  <Select
                      labelId="fat-val-label"
                      id="fat-val"
                      value={FatVal}
                      onChange={handleChangeFatVal}
                      label="Valor"
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={35}>35</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.tableHead}>
                Total
              </TableCell>
              <TableCell id='total-comp-alm' align="center">
              </TableCell>
            </TableRow>

          </TableBody>

        </Table>
      </TableContainer>
    
      {/* Macros Peso/Energia */}
      <TableContainer id="macros-calculated-table" component={Paper} style={{textAlign:'center', minWidth:'360px', marginBottom:'24px'}}>
        <Table aria-label="collapsible table">

          <TableHead style={{backgroundColor:'#047857'}}>
            <TableRow>
              
              <TableCell align="center" colSpan={3}
                style={{fontWeight: 'bold', color:'#111827', textTransform:'uppercase', position:'relative', backgroundColor:'#FCA5A5'}}
              >
                <DonutLargeIcon style={{position:'absolute', fill:'#FEF2F2', fillOpacity:0.5, left:0, top:0, zIndex:1, width:'100%', height:'100%'}} />
                <Typography variant="h6" component="h1" style={{position:'relative', zIndex:2, fontWeight: 'bold', color:'#111827', textTransform:'uppercase'}}>
                  Macronutrientes
                </Typography>
              
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            
            <TableRow>
              <TableCell align="center" colSpan={2} style={{marginLeft:'8px'}}>
                com base na Taxa Metabólica
              </TableCell>
              <TableCell align="center" id='tm-red-val' className={classes.textHead} >
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={3}>
                <span style={{marginLeft:'8px'}}>
                  a ingestão de macronutrientes necessária para atingir a taxa metabólica é:
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Nutriente
              </TableCell>
              <TableCell align="center" className={classes.textHead}>
                Calorias (kcal)
              </TableCell>
              <TableCell align="center" className={classes.textHead}>
                Peso (g)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Carboidrato
              </TableCell>
              <TableCell align="center" id='carbo-kcal-val'>
                400
              </TableCell>
              <TableCell align="center" id='carbo-g-val'>
                100
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Proteína
              </TableCell>
              <TableCell align="center" id='prot-kcal-val'>
                400
              </TableCell>
              <TableCell align="center" id='prot-g-val'>
                100
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Gordura
              </TableCell>
              <TableCell align="center" id='fat-kcal-val'>
                900
              </TableCell>
              <TableCell align="center" id='fat-g-val'>
                100
              </TableCell>
            </TableRow>

          </TableBody>

        </Table>
      </TableContainer>

      {/* Macros por Refeicao Peso/Energia */}
      <TableContainer id="macros-porrefeicao-table" component={Paper} style={{textAlign:'center', minWidth:'360px', marginBottom:'24px'}}>
        <Table aria-label="collapsible table">

          <TableHead style={{backgroundColor:'#047857'}}>
            <TableRow>
              
              <TableCell align="center" colSpan={3}
                style={{fontWeight: 'bold', color:'#111827', textTransform:'uppercase', position:'relative', backgroundColor:'#FCD34D'}}
              >
                <RestaurantIcon style={{position:'absolute', fill:'#FFFBEB', fillOpacity:0.5, left:0, top:0, zIndex:1, width:'100%', height:'100%'}} />
                <Typography variant="h6" component="h1" style={{position:'relative', zIndex:2, fontWeight: 'bold', color:'#111827', textTransform:'uppercase'}}>
                  Macronutrientes por Refeição
                </Typography>
              
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>

            <TableRow>
              <TableCell colSpan={3} style={{marginLeft:'8px'}}>
                <span style={{marginLeft:'8px'}}>
                  quantas refeições por dia você quer fazer?
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} align="center" className={classes.textHead}>
                  Quantidade de Refeições
              </TableCell>
              <TableCell align="center">

                <FormControl variant="outlined">
                    <InputLabel id="Nref-val-label">Valor</InputLabel>
                    <Select
                        labelId="Nref-val-label"
                        id="Nref-val"
                        value={NrefVal}
                        onChange={handleChangeNrefVal}
                        label="Valor"
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                    </Select>
                  </FormControl>

              </TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell align="center" colSpan={2} style={{marginLeft:'8px'}}>
                com base na Taxa Metabólica
              </TableCell>
              <TableCell align="center" id='tm-redref-val' className={classes.textHead} >
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={3}>
                <span style={{marginLeft:'8px'}}>
                  a ingestão de macronutrientes por refeição necessária para atingir a taxa metabólica é:
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Nutriente
              </TableCell>
              <TableCell align="center" className={classes.textHead}>
                Calorias (kcal)
              </TableCell>
              <TableCell align="center" className={classes.textHead}>
                Peso (g)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Carboidrato
              </TableCell>
              <TableCell align="center" id='carbo-kcal-val-ref'>
                400
              </TableCell>
              <TableCell align="center" id='carbo-g-val-ref'>
                100
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Proteína
              </TableCell>
              <TableCell align="center" id='prot-kcal-val-ref'>
                400
              </TableCell>
              <TableCell align="center" id='prot-g-val-ref'>
                100
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" className={classes.textHead}>
                Gordura
              </TableCell>
              <TableCell align="center" id='fat-kcal-val-ref'>
                900
              </TableCell>
              <TableCell align="center" id='fat-g-val-ref'>
                100
              </TableCell>
            </TableRow>

          </TableBody>

        </Table>
      </TableContainer>

    </React.Fragment>
  )
}

function SendResultForm(props) {
  const { mm, mg, ag, tm, CarboVal, ProtVal, FatVal, RdTMVal, NrefVal } = props;
  const [SendEmailVal, setSendEmailVal] = React.useState('brunovernochi@gmail.com');
  const handleChangeSendEmailVal = (event) => {
    setSendEmailVal(event.target.value);
  };

  const [SendEmailState, setSendEmailState] = React.useState({submited:false, shipped:false, error:false});
  const handleChangeSendEmailState = (val) => {
    setSendEmailState(val);
  };

  const [SendEmailres, setSendEmailres] = React.useState({status: false});
  const handleChangeSendEmailres = (val) => {
    setSendEmailres(val);
  };

  const [recapTest, setrecapTest] = React.useState(true);
  const handleChangerecapTest = (val) => {
    setrecapTest(val);
  };

  const NewTM = tm - RdTMVal;
  const CarbKcalNode = Math.round((CarboVal/100) * NewTM);
  const CarbgNode = Math.round(((CarboVal/100) * NewTM) / 4);
  const ProtKcalNode = Math.round((ProtVal/100) * NewTM);
  const ProtgNode = Math.round(((ProtVal/100) * NewTM) / 4);
  const FatKcalNode = Math.round((FatVal/100) * NewTM);
  const FatgNode = Math.round(((FatVal/100) * NewTM) / 9);
  // por ref
  const CarbKcalrefNode = Math.round(((CarboVal/100) * NewTM) / NrefVal);
  const CarbgrefNode = Math.round((((CarboVal/100) * NewTM) / 4) / NrefVal);
  const ProtKcalrefNode = Math.round(((ProtVal/100) * NewTM) / NrefVal);
  const ProtgrefNode = Math.round((((ProtVal/100) * NewTM) / 4) / NrefVal);
  const FatKcalrefNode = Math.round(((FatVal/100) * NewTM) / NrefVal);
  const FatgrefNode = Math.round((((FatVal/100) * NewTM) / 9) / NrefVal);
  
  async function encryptMsg(data) {
    
    const publicKeyArmored = process.env.GATSBY_PUBLIC_KEY.replace(/\\n/g, '\n')
    const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });
    
    const encrypted = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: data }), // input as Message object
      encryptionKeys: publicKey
    });

    return encrypted;
  }
  
  async function submitEmail(e) {
    e.preventDefault();

    handleChangeSendEmailState({submited: true, shipped: false, error: false});
    handleChangerecapTest(true)

    const payload = {
      "mm": mm, "mg": mg, "ag": ag,
      "tm": tm, "redeng": RdTMVal, "redtm": NewTM,
      "cmal": {
        "carb": CarboVal,
        "prot": ProtVal,
        "fat": FatVal,
      },
      "macro" : {
        "carb": {"kcal": CarbKcalNode, "g": CarbgNode},
        "prot": {"kcal": ProtKcalNode, "g": ProtgNode},
        "fat": {"kcal": FatKcalNode, "g": FatgNode},
      },
      "refs": NrefVal,
      "macroref" : {
        "carb": {"kcal": CarbKcalrefNode, "g": CarbgrefNode},
        "prot": {"kcal": ProtKcalrefNode, "g": ProtgrefNode},
        "fat": {"kcal": FatKcalrefNode, "g": FatgrefNode},
      },
    }

    const data = await encryptMsg(JSON.stringify(
      {
        email: SendEmailVal,
        data: payload,
      }
    )).then(encrypted => {return encrypted;});
   
    const response = await fetch(`/api/macros`, {
      method: `POST`,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ token: data }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        setSendEmailres(resData)
      })
  };

  React.useEffect(() => {

    if (SendEmailres.status === "success") {
      handleChangeSendEmailState({submited: true, shipped: true, error: false});

      const timer = setTimeout(() => {
        handleChangeSendEmailState({submited: false, shipped: false, error: false});
        handleChangeSendEmailres({status: false});
        handleChangerecapTest(false)
      }, 5000);
  
      return () => clearTimeout(timer);
      
    } else if (SendEmailres.status === "fail") {
      //alert("Message failed to send");
      //erro no envio tente novamente
      handleChangeSendEmailState({submited: true, shipped: false, error: true});

      const timer = setTimeout(() => {
        handleChangeSendEmailState({submited: false, shipped: false, error: false});
        handleChangeSendEmailres({status: false});
        handleChangerecapTest(false)
      }, 5000);

      return () => clearTimeout(timer);

    }
    
  }, [SendEmailres]);

  const KeySite = '6LcO0twbAAAAACMwe6CkelcOOIHYPyYVfPATpzyH'

  const PUBLIC_KEY = '-----BEGIN PGP PUBLIC KEY BLOCK-----\n\nmQENBGEIRmIBCAC62CRMnturH0UWTa74wBO4QTQkTv4p6wpgNHUumbT0x6hlsbpN\n3zHRcFlsTyFKK6zqnUpxOGbGE+BHt8Fi3/AimhjzowhyylsY9oxDUIu+MBmW7TXF\nLFwTevKpb8F41R8HC11PIwiidT522/xDd8onobb83+Bc6qSreBnzue3dqf6CHV14\nroBpjD8rNO6Opw+h6ZcAKMAXJ40r0E9GcihxaEsl9dEh5vezU48Ot0AyxJj2Z9vs\nfvSuqg6++HH/AzGb/xHBwcj+hhZT1ByqtmaxTVRtJlw83Y1FQ2mYk/+icsVn4ezC\nU29X40tI9dFo+w/TrFqLZyS4aRoGFq+LRWTDABEBAAG0KEJydW5vIFZlcm5vY2hp\nIDxicnVub3Zlcm5vY2hpQGdtYWlsLmNvbT6JAU4EEwEKADgWIQT40SYr3DTc7c66\nJZA6hBrcoA+xAwUCYQhGYgIbAwULCQgHAgYVCgkICwIEFgIDAQIeAQIXgAAKCRA6\nhBrcoA+xA2wSCAC6kbr6fkB6lkOYAnZwBtz2lit6XKdy/RGQtOloYZnXGKBSLGsi\n/l6oIZv/2x1Pne1ib/QEjOWekzLj7M6o3aduDXbt9BqHDItMXYvvAyDcbkuK8fsB\nfPi9ciNJFJomsGk89JTOSKAOnCrzBiN5JI9AnWFcZ9ukXGw1fylomPqd4Rva9wji\nc03Lran7z4c+3wUbmZZadmD8aSWe68f+JSKxifStAstU5QIah8RjGd0c4d4wBEWz\nKPH4pUgeGZu5wGPaW/P/kyEpoTEV5NmCmp5ZN65MKau/udGWr6akQakurVJ26DK0\npILxUQTFoV14RlA90R9UdjdbnL29usRvwb+ZuQENBGEIRmIBCAC67+Q+8ulvfoAY\nQB/5gs/qd7eicCDFiM4rrNvy4D/my/aXj4akuyaqLdDxViFY0s1SQGgurHfhF0Yu\n3Sev063m9hnojA14FLT/xWKM+j2I+p3lWqnJJuewg70l5KrqHt9jMY9kdvN/yvLn\nPw+LOmgnexlarBEUFRvhB8MopHqXVPLzZ4jx5Gg3JVTr1I/da5WL7dvYxvxfyj3J\nxYtsNImffwC2Q0qkzCoJCvIFOF4nrUemMdAOTgrd1sE2qNiUaANBTneXdZpTD5T6\nsw8aXWSJUOEbkATe+rwgi48ONpCiUQRDGF0WXykab348CRA+riyN3e9xi1TZjz15\nUo80BBA9ABEBAAGJATYEGAEKACAWIQT40SYr3DTc7c66JZA6hBrcoA+xAwUCYQhG\nYgIbDAAKCRA6hBrcoA+xA7zEB/474HbINkxTmWuDrxPU0Shx2woWM8plzddmx5II\nv0Wiuo5pRNNiNjr0HhGvAkHQro/ZE2k3V8xUk+Ips3dHZrIThxSFxyrAhP+actUn\nmbbCHq9qkucZq1xwZTc9fTXVkDvqBvYv6WgC81ud5SbPvqFshjda8NRLQpfeqeZW\nfH5RH8FGa8G26kTgVDHibtn7GHffD5moGikBhp0vMCezJPVFLe8B0chOcu2fZpdQ\nT7zsKh90UNQEueVLpMpn4VOWwYQ6rQkc/1zlzmd5YMrjSzINH0xPBZjrFV4Th5MY\nxau7cB6kbfBBAkL/vdgR2Sc3zbFoIShWrIzs8vDUJlhFKqz1\n=KhmR\n-----END PGP PUBLIC KEY BLOCK-----'

  return (
    <React.Fragment>
      <TableContainer id="body-composition-table" component={Paper} style={{textAlign:'center', minWidth:'360px', marginBottom:'24px'}}>
      <form style={{width:'100%'}}
        onSubmit={submitEmail}
      >

      <TableContainer component={Paper} style={{textAlign:'center', minWidth:'360px'}}>
        <Table aria-label="collapsible table">

          <TableHead style={{backgroundColor:'#047857'}}>
            
            <TableRow>
              <TableCell align="center" colSpan={2}
                style={{fontWeight: 'bold', color:'#111827', textTransform:'uppercase', position:'relative', backgroundColor:'#D97706'}}
              >
                <DoubleArrowIcon style={{position:'absolute', fill:'#FCD34D', fillOpacity:0.5, left:0, top:0, zIndex:1, width:'100%', height:'100%'}} />
                <Typography variant="h6" component="h1" style={{position:'relative', zIndex:2, fontWeight: 'bold', color:'#111827', textTransform:'uppercase'}}>
                  Enviar Resultados
                </Typography>
              
              </TableCell>
            </TableRow>
           
          </TableHead>

          <TableBody>

            <TableRow>
              <TableCell colSpan={2}>
                <span style={{marginLeft:'8px'}}>
                  envie os resultados para o seu email:
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
                justify="center" alignItems="center" alignContent="center"
                style={{margin: '.5rem auto'}}
              >

                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                  <ReCaptchaV2 sitekey={process.env.GATSBY_SITE_KEY}
                  onChange={() => {handleChangerecapTest(false)}}
                  onExpired={() => {handleChangerecapTest(true)}}
                  style={{width:'305px',margin:'0 auto'}}
                  />
                </Grid>
                <Grid item xs={11} sm={11} md={10} lg={9} xl={8}>
                  <FormControl variant="outlined" style={{width:'100%',margin:'.25rem'}}>
                    <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      required
                      type={'email'}
                      error={SendEmailVal ? false : true}
                      helperText="Email para envio."
                      value={SendEmailVal}
                      onChange={handleChangeSendEmailVal}
                      endAdornment={
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      }
                      labelWidth={50}
                    />

                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={11} md={10} lg={9} xl={8}>
                  <FormGroup style={{width:'100%',margin:'.25rem'}}>
                    <FormControlLabel
                      value="on"
                      control={<Checkbox required color="primary" />}
                      label={<Typography component={'p'} style={{fontSize:'.9rem',textAlign:'justify'}}>De acordo com as Leis 12.965/2014 e 13.709/2018, que regulam o uso da Internet e o tratamento de dados pessoais no Brasil, autorizo INFO Nutrientes a enviar notificações por e-mail ou outros meios e concordo com sua Política de Privacidade.</Typography>}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                  <Button
                    id="send-results-btn"
                    disabled={recapTest}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disableElevation
                    style={
                      SendEmailState.submited ?
                        {margin:'.25rem',lineHeight:'.85rem',color:'#111827'}
                      :
                        {margin:'.25rem',lineHeight:'.85rem'}
                    }
                    startIcon={
                      SendEmailState.submited ?
                        SendEmailState.shipped ?
                          <DoneAllIcon style={{color:'#064E3B'}} size="2rem" />
                        :
                          SendEmailState.error ?
                            <ErrorIcon style={{color:'#7F1D1D'}} size="2rem" />
                          :
                            <CircularProgress style={{color: '#111827'}} size="2rem"/>
                      :
                        ''
                    }
                    endIcon={
                      SendEmailState.submited ? SendEmailState.shipped ? '' : '' : <SendIcon />
                    }

                  >
                    {
                      SendEmailState.submited ?
                        SendEmailState.shipped ?
                          "Enviado com Sucesso"
                        :
                          SendEmailState.error ?
                            "Erro no envio"
                          :
                            "Enviando..."
                      : 
                        "Enviar"
                    }
                  </Button>
                  
                  <Grid container justify="center" alignItems="center" alignContent="center">
                    <Grid item>
                      <LockIcon style={{fill:'#6B7280'}}/>
                    </Grid>
                    <Grid item>
                      <Typography component={'p'} style={{fontSize:'.7rem',textAlign:'justify', color:'#6B7280'}}>
                        Site criptografado, suas informações estão seguras. 
                      </Typography>
                    </Grid>

                  </Grid>

                </Grid>

              </Grid>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

      </form>
      </TableContainer>
    </React.Fragment>

  )
}